export async function checkUserProfileEx(user_email) {
	// Set query URL
	const PROJECT_ID = "ugztar6v";
	const DATASET = "production";
	// query person table based on email address
	const QUERY = encodeURIComponent(`*[_type == "person"] { email }`);
	// Combine to one query URL
	let URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

	try {
		const res = await fetch(URL);
		if (res.status === 200) {
			const data = await res.json();
			const result = await data.result;
			// console.log(result);
			// true 存在, false 不存在
			let temp = false;
			result.map((item) => {
				if (item.email === user_email) {
					temp = true;
				}
			});
			return temp;
		} else {
			alert("Error", res.status);
		}
	} catch (err) {
		console.log(err);
	} finally {
		console.log("all user email fetch finished");
	}
}
