/** @format */

// Last modified: 22/12/2022 12:38

import React from "react";
import styled from "styled-components";
import "./registor.css";
import logo from "../icon-and-img/logo.png";
import jwt from "jsonwebtoken";

import {
	Form,
	Input,
	Button,
	Checkbox,
	Space,
	ConfigProvider,
	Radio,
	message,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import "antd/dist/reset.css";
import { checkUserProfileEx } from "./checkUserEx";
import { checkUserAccountEx } from "./checkUserAccountEx";
import { createUserProfile } from "./createUserProfile";
import { navigate } from "gatsby";

const Left = styled.div`
	display: flex;
	flex-direction: row;
`;

const First = styled.div`
	width: 50px;
	height: 50px;
	background-color: red;
`;

const Second = styled.div`
	width: 50px;
	height: 50px;
	background-color: red;
`;

// Get this kind of data from sanity.io
let PROJECT_ID = "ugztar6v";
let DATASET = "production";
let QUERY = encodeURIComponent('*[_type == "Account"]');
let URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

class Registor extends React.Component {
	state = {
		login: 1,
		email: "",
		pass: "",
		name: "",
		borderColor: "black",
		emailBorder: "black",
		passFormat: 0,
		userProfileEx: false,
		userAccountEx: false,
		invitationCode: "",
	};

	//Transfer some values to father component(login-component.jsx).
	handleSend = async () => {
		this.props.getMsg(this.state.login);
	};

	//  Add new register to the sanity database.
	addData = () => {
		// Compose the URL for your project's endpoint and add the query.
		const mutations = [
			// insert the data into database with the format below.
			{
				createOrReplace: {
					// type是表的名字 (_type represent the table name in sanity backend).
					_type: "account",
					//name是字段的名字 (name represent the trecord name in sanity in backend.)
					email: this.state.email,
					password: this.state.pass,
					name: this.state.name,
				},
			},
		];

		//Get this token from the sanity banckend.
		const tokenWithWriteAccess =
			"skTSHpqak2h6vCVon1aIeXCK0mIKhtmp3A3jtffuDMm9A0nTzFzPh8pf9eL9t8rvLfMBlbNXNA0u29I5guK2zz8OvujS5JdakpRUKZGSU4YMjLoJQhIEbfO0w61KekyuevtV2W4pctWvZOhDUi0KwaoA6xNKgUqso9jnHR7rEQ83SMwOptwV";

		fetch(
			`https://${PROJECT_ID}.api.sanity.io/v2021-06-07/data/mutate/${DATASET}`,
			{
				method: "post",
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${tokenWithWriteAccess}`,
				},
				// The data need json format.
				body: JSON.stringify({ mutations }),
			}
		)
			.then((response) => response.json())
			.then((result) => console.log(result))
			.catch((error) => console.error(error));

		// this.writeToLocalDatabase()
	};

	validateEmail = (email) => {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
			this.setState({
				emailBorder: "black",
			});
		} else {
			this.setState({
				emailBorder: "red",
			});
		}
	};

	validateEmailOnSubmit = (email) => {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
			return true;
		} else {
			return false;
		}
	};

	// onRegister = async () => {
	// 	checkUserAccountEx(this.state.email).then((result) => {
	// 		this.setState({ userAccountEx: result });
	// 		console.log("userAccountEx");
	// 		console.log(this.state.userAccountEx);
	// 	});
	
	// 	checkUserProfileEx(this.state.email).then((result) => {
	// 		this.setState({ userProfileEx: result });
	// 		console.log("userProfileEx");
	// 		console.log(this.state.userAccountEx);
	// 	});
	// };

	validatePassword = (password) => {
		const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
		// console.log(password);
		// console.log(regex.test(password));
		return regex.test(password);
	};

	onRegister = async () => {
		if (this.validateEmailOnSubmit(this.state.email)) {
			if (this.validatePassword(this.state.pass)) {
				if (this.state.invitationCode === "h44tNUTe7pZEYRWQydqj") {
					const userAccountEx = await checkUserAccountEx(
						this.state.email
					);
					// console.log(userAccountEx);
					if (userAccountEx) {
						alert("This email address has been registered");
					} else {
						checkUserProfileEx(this.state.email).then((result) => {
							if (result) {
								this.addData();
							} else {
								this.addData();
								createUserProfile(
									this.state.name,
									this.state.email
								).then((result) => {
									if (typeof window !== `undefined`) {
										window.alert("Register successful");
										window.localStorage.setItem(
											"email",
											this.state.email
										);
									}
									navigate("/selfprofile", {
										replace: true,
									});
								});
							}
						});
					}
				} else {
					alert("Please enter a valid invitation code");
				}
			} else {
				alert(
					"Password must be 8 characters long and contain at least one uppercase letter, one lowercase letter, and one digit."
				);
			}
		} else {
			alert("Please enter a valid email address");
		}
	};

	// writeToLocalDatabase = () =>{
	//   //本地数据库校验，使用express编写。
	//   const jsonString = JSON.stringify({
	//     email: this.state.email,
	//     password: this.state.pass,
	//     name: this.state.name,
	//   });

	//   const url = 'http://localhost:5005/user/auth/register'

	//   const request = {
	//     headers: {
	//       'Content-Type': 'application/json',
	//     },
	//     method: 'POST',
	//     body: jsonString
	//   }

	//   fetch(url, request).then(response => response.json()).then(data => {
	//     console.log(data)
	//     if (data.error !== undefined) {
	//       alert(data.error)
	//     } else {
	//       alert('Register local database success!')
	//     }
	//   }).catch(error => console.error(error))
	// }

	render() {
		//This page is only for registor not for login.
		return (
			<div className="registor-wrapper">
				<div className="registor-content">
					<div className="specific-content-registor">
						<div className="image-wrapper-registor">
							<img
								src={logo}
								alt=""
								style={{ width: "120px", height: "83px" }}
							/>
						</div>

						<div className="registorInfo">
							<div className="registor-title">Create Account</div>

							<div className="fullname-input">
								<input
									type="email"
									className="login-email"
									placeholder="Full name"
									onChange={(e) => {
										this.setState({
											name: e.target.value,
										});
									}}
								/>
							</div>

							<div className="pass-input">
								<Input
									placeholder="Email"
									style={{
										borderColor: this.state.emailBorder,
									}}
									className="login-email"
									onChange={(e) => {
										this.setState({
											email: e.target.value,
										});
										this.validateEmail(this.state.email);
										if (e.target.value == "") {
											this.setState({
												emailBorder: "black",
											});
										}
									}}
								/>
							</div>

							<div className="pass-input">
								{/* <button
									onClick={() => {
										this.validatePassword(this.state.pass);
									}}
								>
									print
								</button> */}
								<Input.Password
									placeholder="Password"
									iconRender={(visible) =>
										visible ? (
											<EyeTwoTone />
										) : (
											<EyeInvisibleOutlined />
										)
									}
									className="login-pass"
									style={{
										borderColor: this.state.borderColor,
									}}
									onChange={(e) => {
										this.setState({
											pass: e.target.value,
										});

										if (
											e.target.value !=
											this.state.confirmPass
										) {
											this.setState({
												borderColor: "red",
												passFormat: 1,
											});
										} else {
											this.setState({
												borderColor: "black",
											});
										}
									}}
								/>
							</div>

							<div className="pass-input">
								<Input.Password
									placeholder="Confirm password"
									iconRender={(visible) =>
										visible ? (
											<EyeTwoTone />
										) : (
											<EyeInvisibleOutlined />
										)
									}
									className="login-pass-confirm"
									style={{
										borderColor: this.state.borderColor,
									}}
									onChange={(e) => {
										this.setState({
											confirmPass: e.target.value,
										});

										if (e.target.value != this.state.pass) {
											this.setState({
												borderColor: "red",
												passFormat: 1,
											});
										} else {
											this.setState({
												borderColor: "black",
											});
										}
									}}
								/>
							</div>
							<div
								className="pass-input"
								style={{ marginTop: "24px" }}
							>
								<Input
									// className="invitation"
									className="login-pass-confirm"
									placeholder="Invitation code"
									onChange={(e) => {
										this.setState({
											invitationCode: e.target.value,
										});
									}}
								/>
							</div>

							<div className="loginBtn" onClick={this.onRegister}>
								Sign Up
							</div>

							<div className="register">
								<div className="dtHvAct">
									Already have an account?
								</div>

								<div
									className="createNewAct"
									onClick={this.handleSend}
								>
									Log In
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Registor;
