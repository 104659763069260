//Last modified: 04/1/2023 17:26

import React from "react";
// import { Redirect } from "react-router-dom";
import styled from "styled-components";
import "./login.css";
import logo from "../icon-and-img/logo.png";
import Registor from "../registor/registor-component.jsx";
import AlertMessage from "./alert-hook.jsx";
import { navigate } from "gatsby";

// steve test pubsh

// antd component.
import {
	Form,
	Input,
	Button,
	Checkbox,
	Space,
	ConfigProvider,
	Radio,
	message,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import "antd/dist/reset.css";
import { checkUserAccountEx } from "../registor/checkUserAccountEx";

const Left = styled.div`
	display: flex;
	flex-direction: row;
`;

const First = styled.div`
	width: 50px;
	height: 50px;
	background-color: red;
`;

const Second = styled.div`
	width: 50px;
	height: 50px;
	background-color: red;
`;

let PROJECT_ID = "ugztar6v";
let DATASET = "production";
// type is the name of the query table.
let QUERY = encodeURIComponent('*[_type == "account"]');
let URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

let QUERY_PEOPLE = encodeURIComponent('*[_type == "person"]');
let URL_PEOPLE = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY_PEOPLE}`;

class LoginClass extends React.Component {
	state = {
		login: 1,
		registor: 0,
		email: "",
		pass: "",
	};

	//   Checkbox is change here.
	onChangeCheckBox = (e) => {
		console.log(`checked = ${e.target.checked}`);
	};

	//Click create account button,
	//the login interface will changed
	//it to registor interface.
	getRegistor = () => {
		this.setState({
			registor: 1,
			login: 0,
		});
	};

	getChildMsg = (msg) => {
		this.setState({
			login: msg,
			registor: 0,
		});
	};

	// login info return.
	successInfo = () => {
		// can't use the hook function.
		const [messageApi, contextHolder] = message.useMessage();
		messageApi.open({
			type: "success",
			content: "Login success",
		});
	};

	// Login function for sanity verification.
	loginFunction = async () => {
		const { messageApi, contextHolder } = this.props;

		// checkUserAccountEx(this.state.email).then((result) => {
		// 	if (!result) {
		// 		// alert("email does not exist")
		// 		messageApi.open({
		// 			type: "error",
		// 			content: "Email Does Not Exist",
		// 			duration: 3,
		// 		});
		// 	} 
		// });

		await fetch(URL)
			.then((res) => res.json())
			.then(({ result }) => {
				if (result.length > 0) {
					// remove the placeholder content
					// Match the password and email from the database,
					// the algorithm is search by order, time complexity is O(n).
					for (var i = 0; i < result.length; i++) {
						if (result[i]["email"] == this.state.email) {
							if (result[i]["password"] == this.state.pass) {
								const success = () => {
									messageApi.open({
										type: "success",
										content: "Login success",
										duration: 3,
									});
									if (typeof window !== `undefined`) {
										window.localStorage.setItem(
											"email",
											this.state.email
										);
									}
								};
								// Using () to use function directly, login success alert below.
								{
									success();
								}
								// Jump to personal information page.
								setTimeout(() => {
									if (typeof window !== `undefined`) {
										window.localStorage.setItem(
											"email",
											this.state.email
										);
									}
									navigate("/selfprofile", { replace: true });
								}, "2000");
								this.getPeopleInfo();
							} else {
								const error = () => {
									messageApi.open({
										type: "error",
										content:
											"Please check the email or password.",
										duration: 3,
									});
								};
								// Using () to use function directly, login success alert below.
								{
									error();
								}
							}
						}
					}
				}
			})
			.catch((err) => console.error(err));
		// 使用本地数据库校验账号密码，否则无法实现 保持登陆状态，用户校验 和 后端返回token 的功能。
		// this.loginVarifiedByLocalDatabase()
	};

	//  loginVarifiedByLocalDatabase = () => {
	//   // Antd alert nessage below.
	//   const {messageApi, contextHolder} = this.props

	//     const {email, pass} = this.state
	//     console.log(this.state.email)
	//     const jsonString = JSON.stringify({
	//       email: this.state.email,
	//       password: this.state.pass,
	//     });
	//     if (email === '') {
	//       alert('Please enter right format!')
	//     } else {

	//       const url = 'http://localhost:5005/user/auth/login'
	//       const request = {
	//         headers: {
	//           'Content-Type': 'application/json',
	//         },
	//         method: 'POST',
	//         body: jsonString
	//       }
	//       fetch(url, request).then(response => response.json()).then(data => {
	//         // console.log(data)
	//         if (data.error !== undefined) {
	//           alert(data.error)
	//         } else {

	//           const success = () => {
	//             messageApi.open({
	//               type: 'success',
	//               content: 'Login success',
	//               duration: 3,
	//             });
	//           };
	//           // Using () to use function directly, login success alert below.
	//           { success() }

	//           // Store token into localstorage to verify identities.
	//           localStorage.setItem('token', data.token)
	//           console.log(localStorage)
	//         //   localStorage.setItem('display', 'none')
	//         //   localStorage.setItem('logoutdisplay', '')
	//         //   localStorage.setItem('owner', email)
	//         //   console.log(localStorage)
	//         //   location.reload()
	//           // return <Redirect to='/Homepage' />
	//         }
	//       }).catch(error => console.error(error))
	//       this.getPeopleInfo()
	//     }
	//   }

	getPeopleInfo = async () => {
		await fetch(URL_PEOPLE)
			.then((res) => res.json())
			.then(({ result }) => {
				if (result.length > 0) {
					// remove the placeholder content
					// Match the password and email from the database,
					// the algorithm is search by order, time complexity is O(n).
					for (var i = 0; i < result.length; i++) {
						if (result[i]["email"] == this.state.email) {
							console.log(result[i]);
						}
					}
				}
			})
			.catch((err) => console.error(err));
	};

	render() {
		//Need the get props in render (antd style).katielouisepaine@gmail.com
		const { messageApi, contextHolder } = this.props;

		//Judge the current page is login or registor,
		//if login == 1, this page is login page, otherwise
		//that is registor page.

		if (this.state.login == 1) {
			return (
				<div className="login-wrapper">
					{/* antd Style requirement below */}
					{contextHolder}
					<div className="login-content">
						<div className="specific-content">
							<div className="image-wrapper">
								<img
									src={logo}
									alt=""
									style={{ width: "120px", height: "83px" }}
								/>
							</div>

							<div className="loginInfo">
								<div className="login-title">Welcome Back</div>

								<div className="email-input">
									{/* This is a antd component below */}
									{/* <input type="email"  className="login-email" placeholder="Email"  /> */}
									<Input
										placeholder="Email"
										//   onChange = {this.saveFormData('email')}
										iconRender={(visible) =>
											visible ? (
												<EyeTwoTone />
											) : (
												<EyeInvisibleOutlined />
											)
										}
										className="login-email"
										onChange={(e) => {
											this.setState({
												email: e.target.value,
											});
										}}
									/>
								</div>

								<div className="pass-input">
									{/* The antd component is using below. */}
									{/* <input type="password" className="login-pass" placeholder="Password" /> */}
									<Input.Password
										//   style = {inputLenght}
										//   onChange = {this.saveFormData('password')}
										placeholder="Password"
										iconRender={(visible) =>
											visible ? (
												<EyeTwoTone />
											) : (
												<EyeInvisibleOutlined />
											)
										}
										className="login-pass"
										onChange={(e) => {
											this.setState({
												pass: e.target.value,
											});
										}}
									/>
								</div>

								<div className="remember-me">
									<div className="rememberMeWrapper">
										{/* <input type="radio" value =" Remember me" /> */}
										{/* <span>Remember me</span> */}
										{/* Antd can change the color by ConfigProvider. */}

										<ConfigProvider
											theme={{
												components: {
													Checkbox: {
														colorPrimary: "#000000",
													},
												},
											}}
										>
											{/* <Checkbox
												onChange={this.onChangeCheckBox}
											>
												Remember me
											</Checkbox> */}
										</ConfigProvider>

										{/* <Checkbox>Remember me</Checkbox> */}
									</div>

									<div
										className="fogetPass"
										onClick={() => {
											alert(
												"Please contact admin\nEmail: viccc.z.wang@gmail.com"
											);
										}}
									>
										Forget Password
									</div>
								</div>

								<div
									className="loginBtn"
									onClick={this.loginFunction}
								>
									Log In
								</div>

								<div className="register">
									<div className="dtHvAct">
										Don't have an account?
									</div>

									<div
										className="createNewAct"
										onClick={this.getRegistor}
									>
										Create Account
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			// After click the create account, the interface will become Registor page.
			return <Registor getMsg={this.getChildMsg} />;
		}
	}
}

function Login() {
	const { messageApi, contextHolder } = AlertMessage();
	return <LoginClass messageApi={messageApi} contextHolder={contextHolder} />;
}

export default Login;
