export async function createUserProfile(theName, theEmail) {
	let PROJECT_ID = "ugztar6v";
	let DATASET = "production";
	let QUERY = encodeURIComponent('*[_type == "person"]');
	let URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

	const mutations = [
		// insert the data into database with the format below.
		{
			createOrReplace: {
				// type是表的名字 (_type represent the table name in sanity backend).
				_type: "person",
				//name是字段的名字 (name represent the trecord name in sanity in backend.)
				email: theEmail,
				name: theName,
			},
		},
	];

	//Get this token from the sanity banckend.
	const tokenWithWriteAccess =
		"skTSHpqak2h6vCVon1aIeXCK0mIKhtmp3A3jtffuDMm9A0nTzFzPh8pf9eL9t8rvLfMBlbNXNA0u29I5guK2zz8OvujS5JdakpRUKZGSU4YMjLoJQhIEbfO0w61KekyuevtV2W4pctWvZOhDUi0KwaoA6xNKgUqso9jnHR7rEQ83SMwOptwV";

	fetch(
		`https://${PROJECT_ID}.api.sanity.io/v2021-06-07/data/mutate/${DATASET}`,
		{
			method: "post",
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${tokenWithWriteAccess}`,
			},
			// The data need json format.
			body: JSON.stringify({ mutations }),
		}
	)
		.then((response) => response.json())
		.then((result) => console.log(result))
		.catch((error) => console.error(error));

	// this.writeToLocalDatabase()
}
