import React from 'react';
import { Button, message } from 'antd';

function AlertMessage() {
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Login success',
      duration: 10,
    });
  };
  return (
    { messageApi, contextHolder }
  );
};

export default AlertMessage;